// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconHome, IconSearch, IconHeart } from '@tabler/icons';

// constant
const icons = {
  IconHome,
  IconSearch,
  IconHeart
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const home = {
  id: 'home-menu',
  type: 'group',
  children: [
    {
      id: 'okamel-home',
      title: <FormattedMessage id="okamel-home" />,
      type: 'item',
      url: '/',
      icon: icons.IconHome,
      breadcrumbs: false
    },
    {
      id: 'okamel-cv',
      title: <FormattedMessage id="okamel-cv" />,
      type: 'item',
      url: '/cv',
      icon: icons.IconHome,
      breadcrumbs: false
    },
    {
      id: 'favorite',
      title: <FormattedMessage id="favorite" />,
      type: 'item',
      url: '/favorite',
      icon: icons.IconHeart,
      breadcrumbs: false
    },
    {
      id: 'search',
      title: <FormattedMessage id="search" />,
      type: 'item',
      url: '/search',
      icon: icons.IconSearch,
      breadcrumbs: false
    }
  ]
};

export default home;
