import { Alert, Grid, Stack } from '@mui/material';
/**
 * massageAlert:
 * massage: text.
 * color: theme.palette.success.main, theme.palette.grey[800]
 * bgcolor: theme.palette.warning.dark
 * severity: success, info, warning, error
 * variant: filled, outlined
 */
const MassageAlert = (massage, severity, color, bgcolor, variant) => (
  <Grid item xs={12}>
    <Stack justifyContent="space-evenly" alignItems="center">
      <Alert variant={variant} severity={severity} sx={{ color, bgcolor, m: 3 }}>
        {massage}
      </Alert>
    </Stack>
  </Grid>
);

export default MassageAlert;
