import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import okamelReducer from './okamelReducer';
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import cartReducer from './cartReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  okamel: okamelReducer,
  customization: customizationReducer,
  snackbar: snackbarReducer,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'berry-'
    },
    cartReducer
  )
});

export default reducer;
