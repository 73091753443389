import home from './home';
import books from './books';
import articles from './articles';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [home, books, articles]
};

export default menuItems;
