import emailjs from 'emailjs-com';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from '../hooks/useScriptRef';
import { useState } from 'react';

const ContactUs = ({ ...others }) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const scriptedRef = useScriptRef();
  const [formMassage, setFormMassage] = useState({});

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          name: '',
          message: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('خطأ في كتابة الإيميل.').max(32).required('يجب كتابة الإيميل'),
          name: Yup.string().max(32).required('يجب كتابة الاسم كاملا.'),
          message: Yup.string().max(1020, 'تجاوزت عدد الأحرف ').required('يرجى كتابة رسالتك.')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            emailjs.sendForm('okamelApp', 'okamelApp', formMassage, 'user_Crydqp9VHIjASb3NnWgbc').then(
              (result) => {
                setStatus({ success: true });
                setErrors({ submit: 'تم إرسال رسالتك بنجاح.' });
                setSubmitting(true);
                console.log(result.text);
              },
              (err) => {
                setStatus({ success: false });
                setErrors({ submit: 'هناك خطأ لم نتمكن من إرسال رسالتك يرجى المحاولة لاحقا.' });
                setSubmitting(false);
                console.log(err.text);
              }
            );
          } catch (err) {
            console.error(err);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: 'هناك خطأ يرجى المحاولة لاحقا.' });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form
            noValidate
            onSubmit={(e) => {
              handleSubmit(e);
              setFormMassage(e.target);
            }}
            {...others}
          >
            <Grid container spacing={matchDownSM ? 1 : 2}>
              <Grid item xs={12}>
                <FormControl fullWidth error={Boolean(touched.name && errors.name)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-name-login">اسمك الكامل</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-name-login"
                    type="email"
                    value={values.name}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="اسمك الكامل"
                    inputProps={{}}
                  />
                  {touched.name && errors.name && (
                    <FormHelperText error id="standard-weight-helper-text-name-login">
                      {errors.name}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-email-login">الإيميل</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="الإيميل"
                    inputProps={{}}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.email}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={Boolean(touched.message && errors.message)} sx={{ ...theme.typography.customInput }}>
                <InputLabel htmlFor="outlined-adornment-message-login">الرسالة</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-message-login"
                  type="message"
                  value={values.message}
                  name="message"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="الرسالة"
                  multiline
                  rows={4}
                  inputProps={{}}
                />
                {touched.message && errors.message && (
                  <FormHelperText error id="standard-weight-helper-text-message-login">
                    {errors.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {errors.submit && !isSubmitting && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            {isSubmitting && errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText sx={{ color: 'green' }}>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="secondary">
                  إرسال الرسالة
                </Button>
              </AnimateButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ContactUs;
