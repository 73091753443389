const baseUrl = 'https://api.okamel.com';
const okamelLinks = {
  okamelBaseUrl: baseUrl,
  categoryApiUrl: `${baseUrl}/api/1.0/category/`,
  categoryBooksApiUrl: `/api/1.0/books-category/`,
  categoryArticlesApiUrl: `/api/1.0/articles-category/`,
  articlesApiUrl: `/api/2.0/articles_okamel/`,
  articleApiUrl: `/api/2.0/article_okamel/`,
  booksApiUrl: `/api/2.0/books_okamel/`,
  bookApiUrl: `/api/2.0/book_okamel/`,
  bookApiSearch: `/api/2.0/search_okamel/`
};

export default okamelLinks;
