import { Link } from 'react-router-dom';
import useFavorite from '../../../../../utils/favorite/useFavorite';
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import OkamelLinks from '../../../../../utils/okamelLinks';
import Category from '../../../../../views/category/Category';
// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
  },
  '& .MuiListItem-root': {
    padding: 0
  }
}));
const BooksList = () => {
  const theme = useTheme();
  const { favorite } = useFavorite('book_library');

  let favoriteList = <></>;
  if (favorite) {
    favoriteList = favorite.map((item) => (
      <ListItemWrapper key={item.nid}>
        <ListItem component={Link} to={item.route} alignItems="center">
          <ListItemAvatar>
            <Avatar alt={item.title} src={`${OkamelLinks.okamelBaseUrl}${item.image}`} />
          </ListItemAvatar>
          <ListItemText primary={item.title} />
        </ListItem>
        <Grid container direction="column" className="list-container">
          <Grid item component={Link} to={item.route} xs={12} sx={{ pb: 2, textDecoration: 'none' }}>
            <Typography dangerouslySetInnerHTML={{ __html: item.body }} variant="subtitle2" sx={{ overflow: 'hidden', height: 65 }} />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <Category label={item.category} routeCategory={item.routeCategory} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItemWrapper>
    ));
  }
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 330,
        py: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
          maxWidth: 300
        },
        '& .MuiListItemSecondaryAction-root': {
          top: 22
        },
        '& .MuiDivider-root': {
          my: 0
        },
        '& .list-container': {
          pl: 7
        }
      }}
    >
      {favoriteList}
    </List>
  );
};

export default BooksList;
