// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBook } from '@tabler/icons';

// constant
const icons = {
  IconBook
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const books = {
  id: 'books-menu',
  title: <FormattedMessage id="books" />,
  caption: <FormattedMessage id="books-menu-desc" />,
  type: 'group',
  children: [
    {
      id: 'books',
      title: <FormattedMessage id="books" />,
      type: 'collapse',
      icon: icons.IconBook,
      children: [
        /* {
          id: 'trweh_books',
          title: <FormattedMessage id="trweh_books" />,
          type: 'item',
          url: '/books/trweh_books'
        }, */
        {
          id: 'other-books',
          title: <FormattedMessage id="other-books" />,
          type: 'item',
          url: '/books/other-books'
        },
        {
          id: 'economic-books',
          title: <FormattedMessage id="economic-books" />,
          type: 'item',
          url: '/books/economic-books'
        },
        {
          id: 'bysticism-books',
          title: <FormattedMessage id="bysticism-books" />,
          type: 'item',
          url: '/books/bysticism-books'
        },
        {
          id: 'islamic-creed',
          title: <FormattedMessage id="islamic-creed" />,
          type: 'item',
          url: '/books/islamic-creed'
        },
        {
          id: 'jurisprudence-and-its-origins',
          title: <FormattedMessage id="jurisprudence-and-its-origins" />,
          type: 'item',
          url: '/books/jurisprudence-and-its-origins'
        },
        {
          id: 'controversial-issues',
          title: <FormattedMessage id="controversial-issues" />,
          type: 'item',
          url: '/books/controversial-issues'
        },
        {
          id: 'about-secularism',
          title: <FormattedMessage id="about-secularism" />,
          type: 'item',
          url: '/books/about-secularism'
        },
        {
          id: 'concepts',
          title: <FormattedMessage id="concepts" />,
          type: 'item',
          url: '/books/controversial-issues/concepts'
        }
      ]
    }
  ]
};

export default books;
