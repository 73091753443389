import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// home page routing
const Landing = Loadable(lazy(() => import('views/pages/landing/index')));
const BooksPage = Loadable(lazy(() => import('views/pages/landing/BooksPage')));
// CV Okamel
const CvOkamel = Loadable(lazy(() => import('views/Cv')));
// Categories
const CategoryBooks = Loadable(lazy(() => import('views/category/books')));
const CategoryArticles = Loadable(lazy(() => import('views/category/articles')));
// BooksSearchTab And ArticlesSearchTab pages
const BookDetails = Loadable(lazy(() => import('views/BookDetails')));
const ArticleDetails = Loadable(lazy(() => import('views/ArticleDetails')));
// Favorite page
const Favorite = Loadable(lazy(() => import('views/Favorite')));
// Search page
const Search = Loadable(lazy(() => import('views/Search')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    // <AuthGuard>
    <MainLayout />
    // </AuthGuard>
  ),
  children: [
    {
      path: '/home',
      element: <Landing />
    },
    {
      path: '/cv',
      element: <CvOkamel />
    },
    {
      path: '/books',
      element: <BooksPage />
    },
    {
      path: '/books/:route',
      element: <CategoryBooks />
    },
    {
      path: '/books/controversial-issues/concepts',
      element: <CategoryBooks />
    },
    {
      path: '/:route/:route/:route',
      element: <BookDetails />
    },
    {
      path: '/:route/:route/:route/:route',
      element: <BookDetails />
    },
    {
      path: '/:route',
      element: <CategoryArticles />
    },
    {
      path: '/:route/:route',
      element: <ArticleDetails />
    },
    {
      path: '/favorite/:tab',
      element: <Favorite />
    },
    {
      path: '/favorite',
      element: <Favorite />
    },
    {
      path: '/search',
      element: <Search />
    },
    {
      path: '/search/books/',
      element: <Search />
    },
    {
      path: '/search/articles/',
      element: <Search />
    },
    {
      path: '/search/books/:searchWords',
      element: <Search />
    },
    {
      path: '/search/articles/:searchWords',
      element: <Search />
    }
  ]
};

export default MainRoutes;
