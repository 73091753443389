import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const useFavorite = (type) => {
  const { favoriteBooks } = useSelector((state) => state.okamel);
  const [favorite, setFavorite] = useState([]);

  useEffect(() => {
    const booksData = [];
    favoriteBooks.forEach((book) => {
      if (book.type === type) {
        booksData.push(book);
      }
    });
    setFavorite(booksData);
  }, [favoriteBooks, type]);

  return { favorite };
};

export default useFavorite;
