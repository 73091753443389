import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { IconList } from '@tabler/icons';
import Chip from '../../ui-component/extended/Chip';
import { useTheme } from '@mui/material/styles';

const Category = ({ label, routeCategory }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const chipSX = {
    height: 24,
    padding: '0 6px'
  };

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.secondary.light,
    height: 28
  };
  const handleClick = () => {
    navigate(routeCategory);
  };
  return (
    <Chip
      sx={chipSuccessSX}
      label={label}
      size="small"
      onClick={handleClick}
      chipcolor="secondary"
      avatar={<IconList color={theme.palette.text.secondary} />}
    />
  );
};
Category.propTypes = {
  label: PropTypes.string,
  routeCategory: PropTypes.string
};
export default Category;
