// action - API fitch reducer
import * as actionTypes from './actions';

export const initialState = {
  data: [],
  loading: true,
  error: null,
  favoriteBooks: localStorage.getItem('favoriteBooks') ? JSON.parse(localStorage.getItem('favoriteBooks')) : [],
  countFavoriteBooks: 0,
  countFavoriteArticles: 0,
  search: ''
};

const okamelReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTION.API_REQUEST:
      return {
        ...state,
        data: [],
        loading: true
      };
    case 'ADD_FAVORITE':
      return {
        ...state,
        favoriteBooks: [action.payload, ...state.favoriteBooks]
      };
    case 'REMOVE_FAVORITE':
      return {
        ...state,
        favoriteBooks: state.favoriteBooks.filter((favorite) => favorite.nid !== action.payload)
      };
    case 'COUNT_FAVORITE_BOOKS':
      return {
        ...state,
        countFavoriteBooks: action.payload
      };
    case 'COUNT_FAVORITE_ARTICLES':
      return {
        ...state,
        countFavoriteArticles: action.payload
      };
    case actionTypes.ACTION.FETCH_DATA:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case actionTypes.ACTION.ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action
      };
    case 'SET_SEARCH':
      return {
        ...state,
        search: action.payload
      };
    default:
      return state;
  }
};

export default okamelReducer;
