// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Button, Card, CardContent, Grid, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';

// assets
import { PhoneAndroidTwoTone } from '@mui/icons-material';
import AnimateButton from '../../../../ui-component/extended/AnimateButton';

// styles

const CardStyle = styled(Card)(({ theme }) => ({
  background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
  border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
  marginBottom: '22px',
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '157px',
    height: '157px',
    background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary[200],
    borderRadius: '50%',
    top: '-105px',
    right: '-96px'
  }
}));

// ==============================|| SIDEBAR MENU Card ||============================== //

const OkamelAppCard = () => {
  const theme = useTheme();

  return (
    <CardStyle>
      <CardContent sx={{ p: 2 }}>
        <List sx={{ p: 0, m: 0 }}>
          <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
            <ListItemAvatar sx={{ mt: 0 }}>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.largeAvatar,
                  color: theme.palette.primary.main,
                  border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                  borderColor: theme.palette.primary.main,
                  background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : '#fff',
                  marginRight: '12px'
                }}
              >
                <PhoneAndroidTwoTone fontSize="inherit" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{ mt: 0 }}
              primary={
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary[800] }}
                >
                  تنزيل التطبيق
                </Typography>
              }
              secondary={<Typography variant="caption">تطبيق موقع فضيلة الدكتور عمر عبدالله كامل.</Typography>}
            />
          </ListItem>
        </List>
        <Grid item>
          <Stack justifyContent="center" alignItems="center">
            <AnimateButton>
              <Button
                href="https://play.google.com/store/apps/details?id=com.okamel.ibnalbaity"
                target="_blank"
                variant="contained"
                size="small"
                sx={{ boxShadow: 'none' }}
              >
                تنزيل التطبيق
              </Button>
            </AnimateButton>
          </Stack>
        </Grid>
      </CardContent>
    </CardStyle>
  );
};

export default OkamelAppCard;
