import ReactDOM from 'react-dom';
// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { persister, store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import config from 'config';

// ==============================|| REACT DOM RENDER  ||============================== //
/* Sentry.init({
  dsn: 'https://fb66f4a660f44dfbbf99ac198d837969@o1074883.ingest.sentry.io/6133496',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
}); */

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <BrowserRouter basename={config.basename}>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
