// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconLicense } from '@tabler/icons';

// constant
const icons = {
  IconLicense
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const articles = {
  id: 'articles-menu',
  title: <FormattedMessage id="articles" />,
  caption: <FormattedMessage id="articles-menu-desc" />,
  type: 'group',
  children: [
    {
      id: 'articles',
      title: <FormattedMessage id="articles" />,
      type: 'collapse',
      icon: icons.IconLicense,
      children: [
        {
          id: 'mgalat',
          title: <FormattedMessage id="mgalat" />,
          type: 'item',
          url: '/mgalat'
        },
        {
          id: 'moderation',
          title: <FormattedMessage id="moderation" />,
          type: 'item',
          url: '/moderation'
        }
      ]
    }
  ]
};

export default articles;
